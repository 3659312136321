const FontFaceObserver = require('fontfaceobserver')

const Fonts = () => {
    console.log('Font Function initiated.');
    return new Promise((resolve, reject) => {
        console.log('Font Function promise started.');

        if(typeof window !== undefined){
            const link = document.createElement('link')
            link.href = "/fonts/fonts.css";
            link.rel = 'stylesheet'
            document.head.appendChild(link)
        }
        const font = new FontFaceObserver('Kiona Regular')

        font.load().then(() => {
            console.log('font loaded.');
            if(typeof document !== undefined){
                document.documentElement.classList.add('Kiona Regular')
            }
            resolve()
        }, (e) => {
            console.log('font not loaded.', e);
        })
    })
}

export default Fonts