import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import Fonts from './fonts/fonts.js';
// import Loader from "react-loader-spinner";
import Typewriter from 'typewriter-effect';
import './App.css';

function App() {
  // let [isLoaded, setIsLoaded] = useState(false);
  let f = Fonts();

  return (
    <React.Fragment>        
      <div className="loading-outer">
        <div className="loading-inner">
          <h1>
            <Typewriter
                options={{
                    cursor: "_",
                    delay: 60,
                    deleteSpeed: 0
                }}
                onInit={(typewriter) => {
                    let lines = [
                        'BUILDING THE FUTURE OF ENTERTAINMENT AND DIGITAL ASSETS',
                    ]
                    typewriter.typeString(lines[0])
                        // .pauseFor(2000)
                        // .deleteChars(lines[0].length)
                        // .typeString(lines[1])
                    typewriter.start();
                }}
            />
        </h1>
        </div>
      </div>
  </React.Fragment>      
  );
}

export default App;
